import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ModalController, ToastController } from '@ionic/angular';
import { Router } from '@angular/router';
import { PageLoaderService } from './page-loader.service';
import { API_URL, LOCAL_PAYMENT_API_URL, LOCAL_PAYMENT_PORT, terminal_check_enable } from '../common/api';
import { LocalStoreService } from '../services/localstore.service';
import { TranslateService } from '@ngx-translate/core';
import { AlertController } from '@ionic/angular';
import { getAnalytics, logEvent } from 'firebase/analytics';

@Injectable({
  providedIn: 'root'
})
export class KioskapiService {
  cartItems: any = [];
  terminalAlert;
  terminalId = this.store.get('terminalid')
  terminalCheckRes:any;
  terminalStatusMsg;
  payment_server;
  

  constructor(
    private httpClient: HttpClient,
    private toastController: ToastController,
    private pageLoaderService: PageLoaderService,
    private router: Router,
    private store: LocalStoreService,
    private modalController: ModalController,
    private translate: TranslateService,
    public alertController: AlertController,
  ) { 
    this.terminalId = this.store.get('terminalid')
  }

  apiReq(method: string, endPoint: string, payload?: any): Observable<any> {
    let user = this.store.get('user')
    let options: any = {
      headers: new HttpHeaders({
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${user.token}`,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Accept-Language': this.store.getLanguageUuid(),
        'Accept-Language-Code': this.store.getLanguageCode()
      })
    }
    switch (method) {
      case 'get':
        return this.httpClient.get(API_URL + endPoint, options);
      case 'post':
        return this.httpClient.post(API_URL + endPoint, payload, options);
      case 'put':
        return this.httpClient.put(API_URL + endPoint, payload);
      case 'patch':
        return this.httpClient.patch(API_URL + endPoint, payload);
      case 'delete':
        return this.httpClient.delete(API_URL + endPoint, payload);
      default:
        return null;
    }
  }

  restaurant_Details(): Observable<any> {
    const payload = {
      "ref": "kiosk",
      'master_restaurant_uuid': localStorage.getItem("master_restaurant_uuid"),
    }
    return this.apiReq('post', 'masterrestaurant/getbyuuid', payload);
  }

  restaurant_Operational(): Observable<any> {
    let restaurantDetails = this.store.getRestaurant();
    let param = {
      "ref": "kiosk",
      "master_restaurant_uuid": restaurantDetails.master_restaurant_uuid
    }
    return this.apiReq('post', 'masterrestaurant/isrestaurantoperational', param)
  }

  Menu_items(): Observable<any> {
    let master_restaurant_uuid = localStorage.getItem("master_restaurant_uuid");
    let user_information_uuid = localStorage.getItem("user_information_uuid");
    let payload = { "ref": "kiosk", "master_restaurant_uuid": master_restaurant_uuid, "user_information_uuid": user_information_uuid };
    // this.pageLoaderService.pageLoaderPersistent(true)  //------------loader
    // return this.apiReq('post', 'mastermenuitem/getcategorymenuitemsbyrestaurantuuid', payload)
    return this.apiReq('post', 'mastermenuitem/getpagermenuitemsbyrestaurantuuid', payload)

  }

  kiosk_settings(): Observable<any> {
    let countryData = this.store.getCountry();
    let countryUuid = countryData['master_country_uuid'];
    let master_restaurant_uuid = localStorage.getItem("master_restaurant_uuid")
    return this.apiReq('get', 'payment/kiosksetting?cid=' + countryUuid)
  }

  appsettings():Observable<any>{
    let countryData = this.store.getCountry();
    let countryUuid = countryData['master_country_uuid'];
    let master_restaurant_uuid = localStorage.getItem("master_restaurant_uuid")
    return this.apiReq('get', 'payment/appsetting?cid=' + countryUuid +'&rid='+master_restaurant_uuid+'&type='+"kiosk")
  }


  Item_detail(data): Observable<any> {
    return this.apiReq('post', 'mastermenuitem/getitemdetailbyitemuuid', data)
  }

  // audit_log():Observable<any>{
  //   let cart_details =JSON.parse( localStorage.getItem("30b1a711dec4bd62b2b9ac89f7bfb1da"))
  //   let store_cart_details={
  //   'cart_id':cart_details,
  //   }
  //   let newpram={
  //   'master_restaurant_uuid': localStorage.getItem("master_restaurant_uuid"),
  //   new_value:store_cart_details
  //   }
  //   return this.apiReq('post','orderinfo/updateauditlog',newpram)
  // }

  create_kiosk_order(data): Observable<any> {
    return this.apiReq('post', 'orderinfo/createkioskorder', data)
  }

  update_order(data): Observable<any> {
    return this.apiReq('post', 'orderinfo/updatekioskorderstatus', data)
  }

  order_details(data): Observable<any> {
    return this.apiReq('post', 'orderinfo/getbyuuid', data)
  }


  updateOrder(data): Observable<any> {
    return this.apiReq('post', 'orderinfo/updatekioskorderinfostatus', data);
  }

  payment_printing_setting(data): Observable<any> {
    return this.apiReq('post', 'masterrestaurant/getrestaurantsetting', data);
  }


  logEvents(screen, payment?, payment_status?) {
    // let payment_response = Object.assign({},payment);
    let restaurant = this.store.get("restaurant")
    let terminalid = this.store.get("terminalid")
    let session_id = this.store.get("sessionId")
    const analytics = getAnalytics();
    logEvent(analytics, 'logEvents', {
      "terminal_id": terminalid,
      "session_id": session_id,
      "restaurant_uuid": restaurant.master_restaurant_uuid,
      "restaurant_status": restaurant.master_restaurant_status,
      "request_from": screen,
      "cart": this.store.get(this.store.getUserId()) ? this.store.get(this.store.getUserId()) : {},
      "payment": payment,
      "payment_status": payment_status
    })
  }


  //Log API Crash/Exception/Error
  audit_log(screen, payment?, payment_status?) {
    // let payment_response =Object.assign({},payment );
    let restaurant = this.store.get("restaurant")
    const payload = {
      "terminal_id": this.store.get("terminalid"),
      "session_id": this.store.get("sessionId"),
      "restaurant_uuid": restaurant.master_restaurant_uuid,
      "restaurant_status": restaurant.master_restaurant_status,
      "request_from": screen,
      "cart": this.store.get(this.store.getUserId()) ? this.store.get(this.store.getUserId()) : {},
      "payment": payment,
      "payment_status": payment_status
    }

    this.apiReq('post', 'ssouserinformation/kioskauditlog', payload)
      .subscribe(
        (res) => {
        }, error => {
        },
      );

  }

  isJsonString(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  isItemCartAvaliable():Observable<any>{
    let restaurantDetails = this.store.getRestaurant();
    let orderitem = this.store.getUserCart();
    this.cartItems = [];
    Object.entries(orderitem).forEach(([k, v]) => {
      Object.entries(v).forEach(([key, value]) => {
        this.cartItems.push(value);
      })
    });
    let data = {
      ref: "kiosk",
      master_restaurant_uuid: restaurantDetails.master_restaurant_uuid,
      orders:   this.cartItems
    }
    return this.apiReq('post', 'orderinfo/isitemavailable', data);
   }


  async terminal(messages) {
    this.store.set('terminal_modal',"true")
    let message = messages;  
      this.terminalAlert = await this.alertController.create({
        cssClass: 'my-custom-class terminalCheck',
        mode: 'md',
        backdropDismiss: false,
        header: 'Unable to Connect',
        message: '<h1>' + message + '</h1> ',
       
      });
    await this.terminalAlert.present();
  }
  
  telemetry() {
    let terminal_connection_check = terminal_check_enable
    if(terminal_connection_check){
      let master_restaurant_uuid = localStorage.getItem('master_restaurant_uuid')
      let terminalId = this.terminalId || this.store.get('terminalid')
      let data ={
        terminal_id : terminalId,
        master_restaurant_uuid: master_restaurant_uuid,
  
      }
      if(master_restaurant_uuid != null && master_restaurant_uuid !=undefined){
    this.check_for_terminal_connection(data).subscribe(
    r => {
     let result1 = this.stripslashes(r)
     let result2 = result1.replace(/“/g, '"');
     this.terminalCheckRes = result2.replace(/”/g, '"');
     let lengths =  this.terminalCheckRes.length
     if ( this.terminalCheckRes.charAt(0) == '"' &&  this.terminalCheckRes.charAt(lengths - 1) == '"') {
       this.terminalCheckRes =  this.terminalCheckRes.slice(1, -1)
     }
     if (this.isJsonString(this.terminalCheckRes)) {
       this.terminalCheckRes = JSON.parse(this.terminalCheckRes)
       } else {
         this.terminalCheckRes = r
       }
  
       if(this.terminalCheckRes.aaData){
         if(this.terminalCheckRes.aaData.is_active == false){
           this.terminalStatusMsg =  this.terminalCheckRes.message
           if(this.terminalAlert == undefined){
             console.log(this.terminalAlert)
             this.terminal(this.terminalStatusMsg)
           }
         
  
         }
         else{
           this.terminal_dismiss()
         }
       }
       else{
           this.terminal(this.terminalCheckRes.aaData)
     }
     })
    }
   }
  }
  
  terminal_dismiss() {
   if( this.terminalAlert!=null  && this.terminalAlert!=undefined ){
     this.terminalAlert.dismiss();
     this.store.remove('terminal_modal')
  }
  else{
  }
  }
  
  check_for_terminal_connection(data) {
  // this.payment_server = LOCAL_PAYMENT_API_URL+":"+LOCAL_PAYMENT_PORT;
  const headers = new HttpHeaders();
  headers.append('Access-Control-Allow-Origin', this.payment_server);
  let payment_api = API_URL +'terminalstatus';
  return this.httpClient.post(
    payment_api, 
    data,
    { headers, responseType: 'text'}
  );
  }
  
  
  stripslashes(str) {
  
  return (str + '')
    .replace(/\\(.?)/g, function (s, n1) {
      switch (n1) {
        case '\\':
          return '\\'
        case '0':
          return '\u0000'
        case '':
          return ''
        default:
          return n1
      }
    })
  }



}

